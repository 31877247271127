@charset 'UTF-8';
@import "/Users/alivevn/Website/vhost/kaza2019/src/scss/utility/_flex.scss";
@import "/Users/alivevn/Website/vhost/kaza2019/src/scss/utility/_media-queries.scss";
@import "/Users/alivevn/Website/vhost/kaza2019/src/scss/utility/_mixin.scss";


/* PAGE TOP
 * -----------------------------------------------*/
@-webkit-keyframes showZoom {
	0% {
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes showZoom {
	0% {
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
.top {
	@include SP {
		.header:not(.header-fixed) {
			#logo {
				margin-top: 18px;
			}
		}
		.btn-mail,
		.btn-menu {
			top: 20px;
		}
	}
}
.topvisual {
	position: relative;
	overflow: hidden;
	.slick-slide {
		overflow: hidden;
		> div {
			font-size: 0;
		}
		&:not(.slick-active) .slide-child {
			-moz-transform: scale(1);
			-ms-transform: scale(1);
			-webkit-transform: scale(1);
			transform: scale(1);
		}
		&:not(.slick-current) {
			opacity: 0;
		}
	}
	.slideshow {
		// width: 100%;
		// height: 100vh;
		position: relative;
		overflow: hidden;
		&:not(.slick-initialized) .slide-child {
			display: none;
			&:first-child {
				display: block;
			}
		}
	}
	.slide-child {
		// position: absolute;
		// z-index: 1;
		// left: 0;
		// top: 0;
		width: 100%;
		height: 100vh;
		-webkit-animation-fill-mode: forwards;
		animation-fill-mode: forwards;
		-webkit-animation-iteration-count: 1;
		animation-iteration-count: 1;
		background: url(../../img/top/kv01.jpg) no-repeat 50%;
		background-size: cover;
		&.second {
			background-image: url(../../img/top/kv02.jpg);
		}
		&.third {
			background-image: url(../../img/top/kv03.jpg);
		}
		&.four {
			background-image: url(../../img/top/kv04.jpg);
		}
		&.five {
			background-image: url(../../img/top/kv05.jpg);
		}
	}
	.slick-animated .slide-child {
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		-webkit-animation-name: showZoom;
		animation-name: showZoom;
		-webkit-animation-duration: 8s;
		animation-duration: 8s;
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
	}
	.slick-list,
	.slick-track {
		height: 100%;
	}
	.slider-track {
		transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
	}
	&-inner {
		@include centerer();
		text-align: center;
		@include font-size(14);
		@include letter-spacing(90);
		line-height: 1.4;
		color: #fff;
		width: 100%;
		padding-top: 69px;
	}
	.ttl-visual {
		@include font-size(23);
		font-weight: 600;
		@include letter-spacing(500);
		line-height: 1.7;
	}
	@include SP {
		.slide-child {
			background-image: url(../../img/top/kv01_sp.jpg);
			&.second {
				background-image: url(../../img/top/kv02_sp.jpg);
			}
			&.third {
				background-image: url(../../img/top/kv03_sp.jpg);
			}
			&.four {
				background-image: url(../../img/top/kv04_sp.jpg);
			}
			&.five {
				background-image: url(../../img/top/kv05_sp.jpg);
			}
		}
		&-inner {
			padding: 0 0 25px;
		}
		.ttl-visual {
			margin-bottom: 5px;
			padding-left: 11px;
		}
	}
}

.sec-top-concept {
	display: table;
	width: 100%;
	background: $maincolor;
	color: #fff;
	text-align: center;
	> * {
		display: table-cell;
		vertical-align: middle;
		width: 50%;
	}
	.box {
		padding: 127px 10px 139px;
	}
	.pic {
		background: url(../../img/top/img_concept.jpg) no-repeat 50% 50%;
		background-size: cover;
	}
	.title {
		@include font-size(26);
		@include letter-spacing(190);
		line-height: 2.05;
		margin-bottom: 42px;
	}
	.desc {
		margin-bottom: 75px;
		@include font-size(18);
		@include letter-spacing(20);
		line-height: 2.35;
		p {
			margin-bottom: 42px;
		}
	}
	@include SP {
		@include flexbox();
		@include flex-wrap(wrap);
		@include flex-direction(column-reverse);
		> * {
			display: block;
			width: 100%;
		}
		.box {
			padding: 57px 15px 65px;
		}
		.title {
			margin-bottom: 34px;
			@include font-size(23);
			@include letter-spacing(150);
			line-height: 1.85;
		}
		.desc{
			margin-bottom: 55px;
			line-height: 2;
			letter-spacing: 0;
			p {
				margin-bottom: 36px;
			}
		}
	}
	@media (max-width: 360px) {
		.desc {
			@include font-size(16);
		}
	}
}

.sec-top-works {
	background: url(../../img/common/bg_pattern01.png) no-repeat 50% 0;
	background-size: 100% 338px;
	padding: 81px 0 88px;
	overflow: hidden;
	.sec-title {
		margin-bottom: 54px;
	}
	@include PC {
		.list-works {
			margin-bottom: 25px;
			.item {
				margin-bottom: 46px;
			}
			.date {
				display: none;
			}
		}
	}
	@include max-screen(767px) {
		.container {
			padding: 0;
		}
		.slider-visible-xs {
			&:not(.slick-initialized) {
				padding-right: 41.2%;
				.item {
					margin: 0 8px 0 0;
				}
			}
			.slick-list {
				padding-right: 41.3%;
			}
			.slick-slide {
				margin-right: 9px;
			}
			.cats {
				display: none;
			}
		}
		.slick-arrow {
			bottom: -59px;
		}
		.slider-number {
			margin-top: 36px;
		}
	}
	@include SP {
		background-image: url(../../img/common/bg_pattern01_sp.png);
		background-size: 100% 200px;
		padding: 57px 0 52px;
		.sec-title {
			margin-bottom: 40px;
		}
		.pic {
			margin-bottom: 19px;
		}
		.date {
			margin-bottom: 5px;
			font-family: $crimson;
			@include font-size(10);
			font-weight: 600;
			@include letter-spacing(150);
			line-height: 1.3;
		}
		.ttl {
			font-family: $ffM;
			@include font-size(18);
			@include letter-spacing(150);
			@include multiLineEllipsis(2);
		}
		.btn-default {
			margin-top: 38px;
		}
	}
}

.sec-top-event {
	background: $gray01;
	color: #fff;
	padding: 94px 0 90px;
	overflow: hidden;
	.sec-title {
		margin-bottom: 85px;
	}
	.list {
		@include flexbox();
		@include flex-wrap(wrap);
		margin: 0 -14px 63px;
		> li {
			position: relative;
			margin-bottom: 10px;
			padding: 0 14px;
			width: 25%;
		}
		.pic {
			position: relative;
			display: table;
			transform: translateZ(0);
			width: 100%;
			margin-bottom: 23px;
			img {
				display: block;
				width: 100%;
			}
		}
		.cats-lg {
			margin-bottom: 10px;
			span {
				display: inline-block;
				vertical-align: top;
				background: $gray04;
				color: $gray01;
				font-family: $crimson;
				@include letter-spacing(250);
				font-weight: 600;
				min-width: 137px;
				line-height: 1.44;
				margin: 0 6px 6px 0;
				padding: 7px 3px;
				@include font-size(12);
				text-align: center;
				&:last-child {
					margin-right: 0;
				}
			}
		}
		.time {
			padding-left: 33px;
			@include font-size(12);
			background: url(../../img/common/icon/icn_calendar.svg) no-repeat 3px 0;
			background-size: 17px 17px;
		}
		.ttl {
			margin-top: 10px;
			@include font-size(15);
			@include letter-spacing(30);
			font-weight: 600;
			font-family: $ffM;
			line-height: 1.9;
			@include multiLineEllipsis(2);
		}
		a {
			display: block;
			color: #fff;
			@include PC {
				&:hover .zoom-img {
					img {
						@include transform_c(scale(1.1));
					}
				}
			}
		}
	}
	@include SP {
		padding: 58px 0 70px;
		.sec-title {
			margin-bottom: 53px;
		}
		.list {
			display: block;
			margin: 0 0 46px;
			li {
				display: block;
				width: 100%;
				margin-bottom: 40px;
				padding: 0;
			}
			a {
				@include clearfix();
			}
			.pic {
				float: left;
				display: block;
				margin-bottom: 10px;
				width: 44.7%;
			}
			.info {
				float: right;
				width: 50%;
			}
			.cats-lg {
				margin-bottom: 8px;
			}
			.time {
				background-position: 3px 2px;
				line-height: 1.8;
				@include font-size(13);
			}
			.ttl {
				clear: both;
				@include font-size(16);
				line-height: 1.75;
				width: 100%;
			}
		}
	}
	@media (max-width: 360px) {
		.list {
			.pic {
				width: 41%;
			}
			.info {
				width: 54%;
			}
			.time {
				padding-left: 22px;
				background-size: 14px 14px;
				@include font-size(11);
				@include letter-spacing(-30);
			}
		}
	}
}

.sec-top-sale {
	background: $gray03;
	padding: 117px 0 72px;
	overflow: hidden;
	.sec-title {
		margin-bottom: 62px;
	}
	.list {
		.item {
			position: relative;
		}
		.pic {
			position: relative;
			display: table;
			width: 100%;
			margin-bottom: 23px;
			img {
				display: block;
				width: 100%;
			}
		}
		.cats {
			margin-bottom: 7px;
			span {
				min-width: 56px;
				background: #fff;
				@include font-size(12);
			}
		}
		.ttl {
			margin-bottom: 9px;
			@include font-size(15);
			@include letter-spacing(30);
			font-weight: 600;
			font-family: $ffM;
			line-height: 1.9;
			@include multiLineEllipsis(2);
		}
		.price {
			color: $gray01;
			font-weight: bold;
			@include font-size(12);
			@include letter-spacing(30);
			.numb {
				display: inline-block;
				vertical-align: bottom;
				margin-right: 6px;
				color: #818161;
				font-family: $crimson;
				line-height: 1;
				@include font-size(22);
				font-weight: normal;
			}
		}
		a {
			display: block;
			@include PC {
				&:hover .zoom-img {
					img {
						@include transform_c(scale(1.1));
					}
				}
			}
		}
	}
	@media (min-width: 768px) {
		.list {
			@include flexbox();
			@include flex-wrap(wrap);
			margin: 0 -14px 41px;
			.item {
				padding: 0 14px;
				width: 25%;
			}
		}
	}
	@media (max-width: 767px) {
		.container {
			padding: 0;
		}
		.slick-prev:hover,
		.slick-prev:focus {
			background: url(../../img/common/slide_prev.svg) no-repeat center;
			background-size: 35px 35px;
		}
		.slick-prev {
			background-image: url(../../img/common/slide_prev.svg);
		}
		.slick-next:hover,
		.slick-next:focus {
			background: url(../../img/common/slide_next.svg) no-repeat center;
			background-size: 35px 35px;
		}
		.slick-next {
			background-image: url(../../img/common/slide_next.svg);
		}
	}
	@include SP {
		padding: 54px 0 63px;
		.sec-title {
			margin-bottom: 51px;
		}
		.list {
			.pic {
				display: block;
				margin-bottom: 21px;
			}
			.cats {
				margin-bottom: 4px;
				span {
					min-width: 45px;
				}
			}
			.ttl {
				margin-bottom: 12px;
				@include font-size(16);
				line-height: 1.625;
			}
			.price {
				@include font-size(13);
				.numb {
					margin-right: 8px;
					@include font-size(25);
				}
			}
		}
		.btn-default {
			margin-top: 39px;
		}
	}
}

.sec-top-catalog {
	margin: 66px 48px 77px;
	.container {
		@include clearfix();
		padding: 6.7% 8.7% 7.66%;
		background: $maincolor;
		color: #fff;
	}
	.catalog-pttl {
		color: #838B56;
		@include font-size(15);
		font-weight: 600;
		@include letter-spacing(300);
		line-height: 1.3;
		margin-bottom: 30px;
	}
	.catalog-title {
		@include font-size(28);
		font-weight: 600;
		@include letter-spacing(150);
		line-height: 1.8;
		margin-bottom: 26px;
	}
	.catalog-img {
		img {
			display: block;
			width: 100%;
		}
	}
	.desc {
		@include font-size(15);
		line-height: 2.15;
		margin-bottom: 47px;
	}
	.box {
		position: relative;
		background: #666955;
		margin-top: 36px;
		padding: 17px 15px;
		@include flexbox();
		@include flex-wrap(wrap);
		@include align-items(center);
		@include justify-content(space-between);
		&:before {
			position: absolute;
			content: "";
			top: 50%;
			left: -22px;
			width: 45px;
			height: 45px;
			margin-top: -21px;
			background: url(../../img/common/icon/icn_expand.png) no-repeat 50% 0;
			background-size: 100% 100%;
		}
		.catalog-img {
			width: 44.2%;
		}
	}
	.tit {
		width: 53%;
		@include letter-spacing(140);
		font-weight: bold;
		line-height: 1.75;
		padding-left: 7.5%;
	}
	.col-left {
		float: left;
		width: 40%;
		padding-top: 4.6%;
	}
	.col-right {
		float: right;
		width: 52.9%;
	}
	@media (max-width: 1280px) {
		.container {
			padding: 5% 5.7%;
		}
		.catalog-title {
			@include font-size(25);
			line-height: 1.7;
		}
		.col-left {
			padding-top: 2.6%;
			width: 47%;
		}
		.col-right {
			width: 50%;
		}
		.tit {
			@include font-size(14);
			padding-left: 5%;
			width: 55%;
			@include letter-spacing(120);
		}
		.box .catalog-img {
			width: 42%;
		}
	}
	@media (max-width: 1160px) {
		margin-left: 30px;
		margin-right: 30px;
	}
	@media (max-width: 1024px) {
		.catalog-title {
			@include font-size(20);
		}
		.col-left {
			padding-top: 0;
			width: 52%;
		}
		.col-right {
			width: 44%;
		}
	}
	@include SP {
		margin: 20px 0 62px;
		.container {
			padding: 59px 15px 50px;
		}
		.col-left,
		.col-right {
			float: none;
			width: 100%;
			text-align: center;
		}
		.catalog-pttl {
			margin-bottom: 17px;
		}
		.catalog-title {
			@include font-size(25);
		}
		.desc {
			margin-bottom: 36px;
			@include font-size(16);
			line-height: 2;
		}
		.box {
			margin: 38px 0 60px;
			padding: 36px 10px 24px;
			display: block;
			&:before {
				top: -16px;
				left: 50%;
				margin: 0 0 0 -23px;
			}
			.catalog-img {
				width: 100%;
				padding: 0 13px;
			}
		}
		.tit {
			margin-bottom: 13px;
			width: 100%;
			padding: 0;
			@include font-size(17);
		}
	}
	@media (max-width: 360px) {
		.catalog-title {
			@include font-size(23);
		}
		.desc {
			@include font-size(13);
		}
		.tit {
			@include font-size(14);
			@include letter-spacing(50);
		}
	}
}

.sec-top-latest {
	margin-bottom: 89px;
	.sec-title {
		margin-bottom: 58px;
		@include font-size(25);
		@include letter-spacing(300);
	}
	.date {
		font-family: $crimson;
		@include font-size(11);
		font-weight: 600;
		@include letter-spacing(50);
	}
	.top-news {
		float: left;
		width: 45.7%;
		ul {
			min-height: 280px;
			li {
				@include flexbox();
				@include flex-wrap(wrap);
				min-height: 56px;
			}
			a {
				@include multiLineEllipsis(2);
				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					@include multiLineEllipsisForFF(24px, 2);
				}
				@-moz-document url-prefix() {
					@include multiLineEllipsisForFF(24px, 2);
				}
				@include PC {
					&:hover {
						color: #818161;
					}
				}
			}
		}
		.date {
			display: inline-block;
			vertical-align: top;
			padding-top: 6px;
			width: 105px;
		}
		.ttl {
			@include flex(1);
			font-family: $ffM;
			font-weight: 600;
			@include font-size(15);
			@include letter-spacing(30);
			line-height: 1.75;
		}
	}
	.top-blog {
		float: right;
		width: 47%;
		.btn-default {
			margin-top: 37px;
		}
	}
	.list-staffblog {
		min-height: 280px;
		margin-top: 62px;
		@include flexbox();
		@include flex-wrap(wrap);
		@include justify-content(space-between);
		> li {
			width: 50%;
			margin-bottom: 41px;
			padding-left: 20px;
		}
		.thumb {
			width: 33.35%;
		}
		.info {
			width: 61.8%;
		}
		.sttl {
			font-weight: bold;
			@include font-size(15);
			@include letter-spacing(30);
		}
	}
	.ctn {
		padding-top: 3px;
		@include font-size(12);
	}
	.post-author {
		margin-bottom: 0;
		.date {
			width: 67px;
		}
		.name {
			padding: 0 0 0 11px;
		}
		.avatar {
			width: 20px;
			height: 20px;
		}
	}
	.btn-default {
		margin-top: 41px;
	}
	@media (max-width: 1280px) {
		.top-blog {
			width: 54%;
		}
	}
	@media (max-width: 980px) {
		margin-bottom: 75px;
		.top-news,
		.top-blog {
			float: none;
			width: 100%;
		}
		.sec-title {
			margin-bottom: 42px;
			@include letter-spacing(500);
		}
		.date {
			@include font-size(12);
			@include letter-spacing(100);
		}
		.top-news {
			margin-bottom: 89px;
			ul {
				min-height: auto;
				height: auto !important;
				li {
					margin-bottom: 33px;
					min-height: 30px;
				}
			}
			.date {
				width: 80px;
			}
			.ttl {
				width: -moz-calc(100% - 80px);
				width: -webkit-calc(100% - 80px);
				width: calc(100% - 80px);
				@include font-size(16);
				line-height: 1.75;
			}
		}
		.btn-default {
			margin-top: 22px;
		}
		.top-blog {
			.sec-title {
				padding-left: 7px;
			}
			.btn-default {
				margin-top: 25px;
			}
		}
		.list-staffblog {
			margin-top: 38px;
			display: block;
			min-height: auto;
			height: auto !important;
			> li {
				width: 100%;
				padding: 0;
				margin-bottom: 25px;
			}
			.info {
				width: 68.8%;
			}
			.sttl {
				@include font-size(17);
			}
			.thumb {
				width: 26.7%;
			}
		}
		.post-author {
			.avatar {
				width: 25px;
				height: 25px;
			}
			.date {
				width: 82px;
				@include font-size(13);
			}
			.name {
				padding-left: 7px;
			}
		}
		.ctn {
			@include font-size(13);
		}
	}
}

.sec-top-about {
	overflow: hidden;
	.about-img {
		background: url(../../img/top/img_kaza02.jpg) no-repeat 50% 0;
		background-size: cover;
		height: 664px;
		width: 62.4%;
		img {
			width: 100%;
		}
	}
	.about-detail {
		width: 47.85%;
		.inner {
			background: #fff;
			padding: 9.6% 10.5% 9.2%;
			@include font-size(15);
			line-height: 1.9;
		}
	}
	.ttl-about {
		margin: 0 0 30px 4px;
		@include font-size(20);
		font-weight: bold;
		@include letter-spacing(100);
	}
	.desc-about {
		margin-bottom: 54px;
		p:not(:last-child) {
			margin-bottom: 28px;
		}
	}
	.grid-land {
		position: relative;
		padding: 0 48px 82px 0;
		&:before {
			position: absolute;
			content: "";
			z-index: -1;
			top: 0;
			left: 260px;
			margin-top: 77px;
			width: 100%;
			height: 100%;
			background: $maincolor;
		}
		.about-img {
			float: left;
		}
		.about-detail {
			float: right;
			margin-left: -20%;
			padding-top: 176px;
		}
		@media (max-width: 1160px) {
			&:before {
				left: 142px;
			}
		}
	}
	.grid-second {
		position: relative;
		padding: 79px 0 0 48px;
		background: $gray03;
		.about-img {
			float: right;
			background-image: url(../../img/top/img_kaza03.jpg);
			margin: 126px 0 -80px;
			height: 648px;
		}
		.about-detail {
			float: left;
			position: relative;
			margin: 0 -20% 0 0;
			.inner {
				padding-top: 10.3%;
				padding-bottom: 10.3%;
			}
		}
		.ttl-about {
			margin: 0 0 25px;
		}
		.desc-about {
			margin-bottom: 35px;
		}
	}
	.grid-subdivision {
		position: relative;
		padding: 182px 48px 106px;
		color: #fff;
		&:before {
			position: absolute;
			content: "";
			z-index: -1;
			top: 0;
			left: 260px;
			width: 100%;
			height: 100%;
			background: #666955;
		}
		.ttl-about {
			float: left;
			width: 374px;
			text-align: center;
			span {
				color: $maincolor;
			}
		}
		.about-detail {
			position: relative;
			margin: 0 0 0 -10%;
			width: auto;
			float: right;
			width: 73.5%;
			.inner {
				background: none;
				max-width: 712px;
				margin: 0 auto;
				padding: 0;
			}
		}
		.desc-about {
			margin-bottom: 42px;
		}
		a.link-line {
			color: #fff;
			border-color: #fff;
		}
		@media (max-width: 1160px) {
			&:before {
				left: 142px;
			}
			.ttl-about {
				width: 204px;
				text-align: left;
			}
			.about-detail {
				width: 66.5%;
				margin: 0;
			}
		}
	}
	@media (max-width: 1160px) {
		.grid-land {
			padding-right: 30px;
		}
		.grid-second {
			padding-left: 30px;
		}
		.grid-subdivision {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
	@include SP {
		& + .top-banner {
			margin-bottom: 19px;
		}
		.about-img {
			float: none !important;
			background: none !important;
			width: 100%;
			height: auto !important;
		}
		.about-detail {
			float: none !important;
			width: 100%;
			.inner {
				padding: 43px 15px 43px 28px;
			}
		}
		.ttl-about {
			margin: 0 0 27px;
		}
		.desc-about {
			margin-bottom: 25px;
		}
		.grid-land {
			padding: 0 0 71px;
			&:before {
				left: 0;
				margin-top: 0;
			}
			.about-detail {
				position: relative;
				padding: 0 0 0 35px;
				margin: -66px 0 0;
			}
		}
		.grid-second {
			padding: 40px 0 0;
			.ttl-about {
				margin: 0 0 27px;
			}
			.about-detail {
				padding-right: 35px;
				.inner {
					padding-top: 43px;
					padding-bottom: 43px;
				}
			}
			.desc-about {
				margin-bottom: 29px;
			}
			.about-img {
				margin: -20px 0 0;
			}
		}
		.grid-subdivision {
			padding: 43px 22px 51px;
			&:before {
				left: 0;
			}
			.ttl-about {
				margin-bottom: 30px;
				float: none;
				width: auto;
				span {
					color: #fff;
				}
			}
			.about-detail {
				float: none;
				width: auto;
			}
			.desc-about {
				margin-bottom: 35px;
			}
		}
	}
	@media (max-width: 360px) {
		a.link-line {
			@include font-size(14);
		}
		.about-detail {
			.inner {
				@include font-size(13);
			}
		}
		.ttl-about {
			@include font-size(17);
			@include letter-spacing(30);
		}
	}
}

.top-banner {
	img {
		width: 100%;
	}
	@include PC {
		background: url(../../img/top/img_kaza01.jpg) no-repeat 50% 0;
		background-size: cover;
		background-attachment: fixed;
		height: 637px;
		&.kaza {
			background-image: url(../../img/top/img_kaza04.jpg);
		}
	}
	.tablet & {
		background-attachment: initial !important;
	}
}

.scroll {
  padding-top: 60px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(-50%,0);
  transform: translate(-50%,0);
  text-decoration: none;
  color: #fff!important;
  font-family: "Crimson Text",serif;
  @include max-screen(767px) {
  	bottom: 10px;
  }
}
.scroll span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 2s infinite;
  animation: sdb05 2s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}